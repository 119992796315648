<template>
  <div>
    <b-card>
      <b-row>
        <!-- <b-col md="4" class="my-1">
            <b-form-group class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="moviename"
                  v-on:input="search($event)"
                  @reset="search($event)"
                  type="search"
                  placeholder="Enter Date you want.."
                />
                <b-input-group-append>
                  <b-button @click="index(created_date)" variant="primary">
                    Search
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col> -->

        <!-- Table -->
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
          >
            <template #cell(user)="data">
              {{ data.value.name }}
            </template>
            <template #cell(created_at)="data">
              {{  momentFormat(data.value , 'DD MMMM YYYY HH:mm:ss') }}
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            v-on:input="paginate($event)"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BButton,
  BCard,
} from "bootstrap-vue";
import locationsApi from "@/Api/Modules/locations";
export default {
  components: {
    BTable,
    BRow,
    BCard,
    BCol,
    BInputGroupAppend,
    BInputGroup,
    BButton,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    // validations
  },
  data() {
    return {
      dir: "ltr",
      longitude: "",
      latitude: "",
      id: "",
      perPage: 10000000,
      currentPage: 1,
      total: "",
      sortBy: "",
      items: [],
      locations: [],
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      // colomns
      fields: [
        { key: "user", label: "Logged User", sortable: true },
        { key: "longitude", label: "Longitude", sortable: true },
        { key: "latitude", label: "Latitude", sortable: true },
        { key: "address", label: "Address", sortable: true },
        { key: "created_at", label: "Created Time", sortable: true },
      ],
    };
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    await this.index();
  },

  methods: {
    async index() {
      await this.$vs.loading({
        scale: 0.8,
      });
      const res = await locationsApi.index();
      this.locations = res.data.data.data;
      this.items = this.locations;
      this.$vs.loading.close();
    },

    //   search(e) {
    //     this.index(e);
    //   },
  },
};
</script>
<style scoped></style>
